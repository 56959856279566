<template>
  <div class="bg-gray-800">
    <div class="mt-4" v-if="selectedDrink === ''">
      <p>Veuillez choisir votre boisson</p>
      <button type="button" @click="selectDrink" class="inline-flex mt-4 items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-gray-600">
        Boisson
      </button>
    </div>
    <div class="mt-4" v-else>
      <p>Votre boisson:</p>
      <p class="uppercase text-lg mt-4">{{ selectedDrink }}</p>
    </div>

    <div class="mt-4" v-if="selectedPosition === ''">
      <p>Veuillez choisir votre position</p>
      <button type="button" @click="selectPosition" class="inline-flex mt-4 items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-gray-600">
        Position
      </button>
    </div>
    <div class="mt-4" v-else>
      <p>la position: </p>
      <p class="uppercase text-lg mt-4">{{ selectedPosition }}</p>
    </div>

    <div class="mt-4" v-if="selectedPlace === ''">
      <p>Veuillez choisir votre endroit</p>
      <button type="button" @click="selectPlace" class="inline-flex mt-4 items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-gray-600">
        Place
      </button>
    </div>
    <div class="mt-4" v-else>
      <p>l'endroit:</p>
      <p class="uppercase text-lg mt-4">{{ selectedPlace }}</p>
    </div>

    <div class="mt-8" v-if="selectedDrink !== '' && selectedPosition !== '' && selectedPlace !== ''">
      <button type="button" @click="reset" class="inline-flex mt-4 items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-gray-600">
        Re-Jouez!
      </button>
    </div>
  </div>

  <div class="absolute bottom-4 right-4">
    <button @click="reset">
      <img alt="Vue logo" class="h-8 w-auto" src="../assets/reset.png">
    </button>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      drink: [
          'Bière',
          'Lupulus',
          'Paix Dieu',
          'TK',
          'Bière tabasco',
          'Kiss Cool',
          'Saumon'
      ],
      position: [
          'Leuleu',
          'Assis',
          'Debout',
          'Poirier',
          'A genoux',
          'Gainage'
      ],
      place: [
          'Sur le Bar',
          'Dans le Bac',
          'Toilette',
          'Sur la table',
          'Sous la table',
          'Dans la douche',
          'Sur une chaise'
      ],
      selectedDrink: '',
      selectedPosition: '',
      selectedPlace: ''
    }
  },
  methods: {
    selectDrink () {
      this.selectedDrink = this.drink[Math.floor(Math.random()*this.drink.length)]
    },
    selectPosition () {
      this.selectedPosition = this.position[Math.floor(Math.random()*this.position.length)]
    },
    selectPlace () {
      this.selectedPlace = this.place[Math.floor(Math.random()*this.place.length)]
    },
    reset () {
      this.selectedPlace = ''
      this.selectedDrink = ''
      this.selectedPosition = ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
