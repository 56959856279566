<template>
  <div class="home p-4">
    <h1 class="my-4 text-xl">L'afond du Destin by Les Moines</h1>
    <img alt="Vue logo" class="h-44 w-auto my-10 mx-auto" src="../assets/biere.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
