import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/drink',
    name: 'Drink',
    component: () => import('../views/Drink')
  },
  {
    path: '/position',
    name: 'Position',
    component: () => import('../views/Position')
  },
  {
    path: '/place',
    name: 'Place',
    component: () => import('../views/Place')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
