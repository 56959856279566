<template>
  <navbar></navbar>
  <div class="bg-gray-800">
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.main-wrapper {
  height: 100vh;
}
</style>
<script>
import Navbar from "./components/Navbar";
export default {
  components: {Navbar}
}
</script>
