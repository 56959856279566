import { createStore } from 'vuex'

export default createStore({
  state: {
    navigation: [
      { name: 'Home', href: '/', current: false },
      { name: 'About', href: '/about', current: false },
      { name: 'Les boissons', href: '/drink', current: false },
      { name: 'Les positions', href: '/position', current: false },
      { name: 'Les endroits', href: '/place', current: false },
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
